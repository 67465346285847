import { Button, Col, Form, Row } from 'react-bootstrap';
import React, { Component } from 'react';

import FormHeader from './formHeader';
import FormUtils from '../../utils/formUtils';
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';
import MaskedInput from 'react-maskedinput';
import PropTypes from 'prop-types';
import RadioInput from '../common/input/radioInput';
import SelectState from '../common/input/selectState';
import SidebarLayout from '../layout/sidebarLayout';

class Step6 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendMail: '',
      additionalPhoneNo: '',
      inquiringMsg: '',
      street: '',
      city: '',
      state: '',
      zip: '',
    };
  }

  onSubmit = (event) => {
    event.preventDefault();
    const {
      sendMail,
      additionalPhoneNo,
      inquiringMsg,
      street,
      city,
      state,
      zip,
    } = this.state;
    const data = {
      mail_information: sendMail ? 'yes' : 'no',
      street,
      city,
      state,
      zip,
      additional_phone: additionalPhoneNo,
      additional_info: inquiringMsg,
    };
    this.props.onSubmit(data);
  };

  onSendMailChange = (value) => {
    this.setState({ sendMail: value });
  };

  onStateChange = (value) => {
    this.setState({ state: value });
  };

  render() {
    const { additionalPhoneNo, inquiringMsg, sendMail, street, city, zip } =
      this.state;
    return (
      <SidebarLayout securityIcons subFooter>
        <FormHeader text='Just One Last Step' />
        <Form id='step-6-form' noValidate onSubmit={this.onSubmit}>
          <Row>
            <Col className='mc-form-input' xs={12}>
              <p className='form-input-label'>
                Would you like rates &amp; information mailed to you?
              </p>
              <RadioInput
                options={[
                  {
                    id: 'yes',
                    label: 'Yes',
                    value: 'yes',
                  },
                  {
                    id: 'no',
                    label: 'No',
                    value: 'no',
                  },
                ]}
                id='mail-rates'
                name='mail-rates'
                onChange={this.onSendMailChange}
                value={sendMail}
              />
            </Col>
            {sendMail === 'yes' && (
              <>
                <Col className='mc-form-input' xs={12}>
                  <Form.Group className='form-input-group sm-mb10 xs-mb10'>
                    <Form.Control
                      id='mail-street'
                      type='text'
                      placeholder='Street'
                      value={street}
                      onChange={(event) => {
                        this.setState({
                          street: event.target.value,
                        });
                      }}
                      onBlur={(event) => {
                        this.setState({
                          street: FormUtils.cleanString(event.target.value),
                        });
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} sm={4} className='mc-form-input'>
                  <Form.Group className='form-input-group sm-mb10 xs-mb10'>
                    <Form.Control
                      id='mail-city'
                      type='text'
                      placeholder='City'
                      value={city}
                      onChange={(event) => {
                        this.setState({
                          city: event.target.value,
                        });
                      }}
                      onBlur={(event) => {
                        this.setState({
                          city: FormUtils.cleanString(event.target.value),
                        });
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} sm={4} className='mc-form-input'>
                  <SelectState onChange={this.onStateChange} />
                </Col>
                <Col xs={12} sm={4} className='mc-form-input'>
                  <Form.Group className='form-input-group sm-mb10 xs-mb10'>
                    <Form.Control
                      id='mail-zip'
                      type='text'
                      placeholder='ZIP Code'
                      value={zip}
                      onChange={(event) => {
                        this.setState({
                          zip: event.target.value,
                        });
                      }}
                      pattern='[0-9]{5}'
                      maxLength='5'
                      minLength='5'
                      inputMode='numeric'
                    />
                    <Form.Control.Feedback type='invalid'>
                      Please enter a valid ZIP Code
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </>
            )}

            <Col className='mc-form-input' xs={12}>
              <p className='form-input-label'>
                If there&apos;s a better number other than the one provided,
                please enter below. (Additional phone no)
              </p>
              <Form.Group
                className='sm-mb10 xs-mb20 form-input-group'
                style={{ height: 50 }}
              >
                <Form.Control
                  id='phone'
                  as={MaskedInput}
                  mask='(111) 111-1111'
                  placeholder='(123) 456-7890'
                  type='tel'
                  value={additionalPhoneNo}
                  onChange={(event) => {
                    this.setState({
                      additionalPhoneNo: event.target.value,
                    });
                  }}
                  pattern='^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$'
                />
              </Form.Group>
            </Col>
            <Col className='mc-form-input' xs={12}>
              <p className='form-input-label'>
                If you are inquiring on behalf of someone or you have additional
                information that you would like to include, please enter below.
              </p>
              <Form.Group className='form-input-group'>
                <Form.Control
                  as='textarea'
                  className='font-family-secondary'
                  placeholder='Additional Info'
                  id='additional-info'
                  name='additional-info'
                  rows={3}
                  maxLength={499}
                  value={inquiringMsg}
                  onChange={(event) => {
                    this.setState({
                      inquiringMsg: event.target.value,
                    });
                  }}
                  onBlur={(event) => {
                    this.setState({
                      inquiringMsg: FormUtils.cleanString(event.target.value),
                    });
                  }}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6} lg={5} className='mc-form-input'>
              <Button
                className='btn btn-primary-custom w-100 mt20'
                type='submit'
                id='step-6-submit-btn'
              >
                <span>
                  Browse my plans
                  <HiOutlineArrowNarrowRight
                    color='white'
                    scale={3}
                    style={{ marginLeft: 10 }}
                  />
                </span>
              </Button>
            </Col>
          </Row>
        </Form>
      </SidebarLayout>
    );
  }
}

Step6.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default Step6;
